<template>
  <div class="row">
    <div class="col">
      <!-- begin::Datatable actions -->
      <div class="mb-7">
        <div class="row align-items-center">
          <div class="col-12 mt-3 col-lg-auto mt-lg-0 ml-lg-auto">
            <overview-filters
              class="btn-block"
              style="min-width: 150px;"

              @apply="onApplyFilters"
            />
          </div>
        </div>
      </div>
      <!-- end::Datatable actions -->

      <datatable
        ref="datatable"
        :url="datatable.url"
        :fields="datatable.fields"
        :filters="datatable.filters"
        :search-fields="datatable.searchFields"
        :sort-order="datatable.sortOrder"
        :on-pagination-data="onPaginationData"

        :transform="transform"
      >
        <!-- begin::Critical -->
        <div slot="critical" slot-scope="props">
          <span
            :class="[
              'label w-100 label-inline label-xl label-rounded py-5',
              (props.rowData.error.critical === true ? 'label-light-danger' : 'label-light-warning')
            ]"
          >
            {{ props.rowData.error.critical === true ? 'Critical' : 'Non-critical' }}
          </span>
        </div>
        <!-- end::Critical -->

        <!-- begin::Actions -->
        <div slot="actions" slot-scope="props">
          <b-dropdown
            ref="actions-dropdown"
            variant="clean btn-sm btn-icon"
            class="dropdown-inline mr-2"
            right
            lazy
            no-caret
            boundary="window"
            menu-class="dropdown-menu-md"
          >
            <template v-slot:button-content>
              <span class="svg-icon">
                <font-awesome-icon :icon="['fas', 'cog']" />
              </span>
            </template>

            <template v-slot:default>
              <ul class="navi flex-column navi-hover py-2">
                <li class="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                  Choose an action:
                </li>

                <li class="navi-separator my-0" />

                <template v-if="props.rowData.json_data && props.rowData.json_data.paraId">
                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="showCorrespondingSettings(props.rowData)">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'cogs']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Corresponding settings</span>
                    </a>
                  </li>

                  <li class="navi-separator my-0" />
                </template>

                <template>
                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(props.rowData, { query: { format: 'csv' } })">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'file-csv']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Download CSV</span>
                    </a>
                  </li>

                  <li class="navi-item">
                    <a class="navi-link cursor-pointer" @click.prevent="downloadLogFile(props.rowData, { query: { format: 'json' } })">
                      <span class="navi-icon">
                        <i>
                          <font-awesome-icon :icon="['fas', 'file-code']" class="w-15px h-15px" />
                        </i>
                      </span>
                      <span class="navi-text">Download JSON</span>
                    </a>
                  </li>
                </template>
              </ul>
            </template>
          </b-dropdown>
        </div>
        <!-- end::Actions -->
      </datatable>

      <!-- begin::Datatable footer -->
      <div class="datatable datatable-default">
        <div class="datatable-pager datatable-paging-loaded">
          <datatable-pagination
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />

          <datatable-pagination-info
            ref="pagination.info"
            @vuetable-paginationinfo:change-rowcount="onChangeRowCount"
          />
        </div>
      </div>
      <!-- end::Datatable footer -->
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { DatatableMixin } from '@vedicium/metronic-vue';
import equipmentMixin from '@/components/pages/sunbeds/view/libs/mixin';

import Equipment from '@/libs/classes/equipment';
import LuxuraLog from '@/libs/classes/luxura_log';
import LuxuraLogError from '@/libs/classes/luxura_log.error';

import overviewFilters from './components/overview-filters.vue';

export default {
  mixins: [equipmentMixin, DatatableMixin],
  components: {
    overviewFilters,
  },
  data () {
    return {
      datatable: {
        url: `${Equipment.uri}/${this.equipment._meta.guid}/logs`,

        fields: [{
          name: 'critical',
          title: 'Type',
          sortField: 'error.critical',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }, {
          name: 'log_date',
          title: 'Date',
          sortField: 'log_date',
          titleClass: 'w-300px',
          dataClass: 'w-300px',
          formatter: (value) => moment.utc(value).tz(this.equipment.location.timezone || 'UTC').format('LLL'),
        }, {
          name: 'error.id',
          title: 'Error ID',
          sortField: 'error.id',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
        }, {
          name: 'error.id',
          title: 'Error description',
          titleClass: 'w-300px',
          dataClass: 'w-300px',
          formatter: (value) => this.$tu(`luxura_logs.error.errors.${value}.description`) || `Error ${value}`,
        }, {
          name: 'log_id',
          title: 'Log',
          sortField: 'log_id',
          titleClass: 'w-100px',
          dataClass: 'w-100px',
          formatter (value) {
            return `#${value}`;
          },
        }, {
          name: 'controller_serial',
          title: 'Controller',
          sortField: 'controller_serial',
          titleClass: 'w-150px',
          dataClass: 'w-150px',
        }, {
          name: 'actions',
          title: 'Actions',
          titleClass: 'w-125px',
          dataClass: 'w-125px',
        }],

        sortOrder: [{
          field: 'log_date',
          direction: 'desc',
        }],

        filters: {
          type: 'error',
          'error.status': 3,
        },

        searchFields: [],
      },
    };
  },

  methods: {
    transform (data) {
      const response = { ...data };
      if (response.data && Array.isArray(response.data)) {
        response.data = response.data.map((row) => new LuxuraLogError().merge(row));
      }
      return response;
    },

    async showCorrespondingSettings (log = null) {
      if (!log || log instanceof LuxuraLog === false) {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        const documents = await this.$ws.get(`${Equipment.uri}/${this.equipment._meta.guid}/logs`, {
          query: {
            filter: {
              type: 'parameter',
              log_id: Number(log.json_data.paraId),
              corresponding_serial: log.corresponding_serial,
            },
          },
        });

        if (documents.length === 0) {
          throw new Error('Corresponding settings not found.');
        }

        this.$router.push({
          name: '/sunbeds/view/:guid/settings/sunbed',
          query: {
            log: documents[0]._meta.guid,
          },
        });
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },

    async downloadLogFile (log = null, options = {}) {
      if (!log || log instanceof LuxuraLog === false) {
        return;
      }

      this.$refs.datatable.setLoading(true);
      try {
        await log.download(options);
      } catch (e) {
        this.$errors.handle(e, { ui_element: true });
        console.error(e);
        return;
      } finally {
        this.$refs.datatable.setLoading(false);
      }
    },

    async onApplyFilters (filters = null) {
      if (!filters) {
        return;
      }

      // Copy current filters
      const datatableFilters = { ...this.datatable.filters };

      // Loop through every known status
      ['type', 'error.id', 'log_date'].forEach((filter) => {
        const value = filters[(filter)];

        switch (filter) {
          case 'type': {
            if (!value) {
              datatableFilters['error.critical'] = null;
            } else if (value.toLowerCase() === 'critical') {
              datatableFilters['error.critical'] = true;
            } else if (value.toLowerCase() === 'non-critical') {
              datatableFilters['error.critical'] = false;
            }
            break;
          }

          case 'log_date': {
            if (!value || !value.start || !value.end) {
              datatableFilters['log_date|lte'] = null;
              datatableFilters['log_date|gte'] = null;
            } else {
              datatableFilters['log_date|lte'] = value.end.getTime();
              datatableFilters['log_date|gte'] = value.start.getTime();
            }
            break;
          }

          default: {
            datatableFilters[(filter)] = value;
            break;
          }
        }
      });

      // Remove 'null' values
      Object.entries(datatableFilters).forEach(([key, value]) => {
        if (value === undefined || value === null) {
          delete datatableFilters[key];
        }
      });

      // Apply filters
      this.$set(this.datatable, 'filters', datatableFilters);
      await this.$nextTick();

      // Go back to page 1 if on other page than 1
      if (this.$refs.datatable.$refs.vuetable.currentPage !== 1) {
        this.$refs.datatable.$refs.vuetable.gotoPage(1);
      } else {
        this.$refs.datatable.reload();
      }
    },
  },
};
</script>
