<template>
  <b-dropdown
    ref="dropdown"
    variant="outline-secondary"
    right
    no-caret
    no-flip
    lazy
    menu-class="dropdown-menu-lg px-8 py-5"

    @hide="onDropdownHide"
  >
    <!-- begin::Button -->
    <template v-slot:button-content>
      <i class="text-brand">
        <font-awesome-icon :icon="['fas', 'filter']" />
      </i>
      Filter
    </template>
    <!-- end::Button -->

    <!-- begin::Dropdown items -->
    <template v-slot:default>
      <form class="p-0" @submit.prevent>
        <div>
          <h5 class="text-dark">Filter options</h5>
        </div>

        <div class="separator separator-solid my-5" />

        <div>
          <!-- begin::Type filter -->
          <div class="mb-5">
            <label class="form-label h6 text-dark">Type</label>
            <v-select
              ref="select:type"

              class="form-control"
              placeholder="Please select"

              v-model="filters.type"

              :options="metadata.filters.type.options"
              :filter="metadata.filters.type.filter"

              @input="onSelectInput"
            >
              <!-- begin::Selected option -->
              <template slot="selected-option" slot-scope="option">
                <span v-text="option.label" />
              </template>
              <!-- end::Selected option -->
              <!-- begin::Option -->
              <template slot="option" slot-scope="option">
                <span v-text="option.label" />
              </template>
              <!-- end::Option -->
            </v-select>
          </div>
          <!-- end::Type filter -->

          <!-- begin::Error filter -->
          <div class="mb-5">
            <label class="form-label h6 text-dark">Error</label>
            <v-select
              ref="select:error"

              class="form-control"
              placeholder="Please select"

              v-model="filters['error.id']"

              :options="metadata.filters['error.id'].options"
              :filter="metadata.filters['error.id'].filter"

              @input="onSelectInput"
            >
              <!-- begin::Selected option -->
              <template slot="selected-option" slot-scope="option">
                <span v-text="$te(`luxura_logs.error.errors.${option.label}.description`) ? `${option.label} - ${$t(`luxura_logs.error.errors.${option.label}.description`)}` : option.label" />
              </template>
              <!-- end::Selected option -->
              <!-- begin::Option -->
              <template slot="option" slot-scope="option">
                <span v-text="$te(`luxura_logs.error.errors.${option.label}.description`) ? `${option.label} - ${$t(`luxura_logs.error.errors.${option.label}.description`)}` : option.label" />
              </template>
              <!-- end::Option -->
            </v-select>
          </div>
          <!-- end::Error filter -->

          <!-- begin::Date filter -->
          <div class="mb-5">
            <label class="form-label h6 text-dark">Date</label>
            <b-dropdown
              ref="select:log_date:dropdown"
              :variant="(!filters.log_date ? 'outline-primary' : 'primary')"
              class="btn-block btn-clean btn-dropdown dropdown-menu-top-unround"
              right
              lazy
              no-caret
              menu-class="w-100 w-md-500px"

              @show="onSelectInput"
            >
              <template v-slot:button-content>
                <i class="mt-n1">
                  <font-awesome-icon :icon="['fas', 'calendar-alt']" />
                </i>

                <span class="pl-3">
                  <template v-if="!filters.log_date" class="text-muted">Please select...</template>
                  <template v-else-if="$moment(filters.log_date.start).isSame(filters.log_date.end, 'date')">{{ $moment(filters.log_date.start).format("LL") }}</template>
                  <template v-else>{{ $moment(filters.log_date.start).format("LL") }} - {{ $moment(filters.log_date.end).format("LL") }}</template>
                </span>
              </template>

              <template v-slot:default>
                <div class="row mx-0">
                  <div class="col-12 border-bottom col-md-8 border-bottom-md-0 border-right-md">
                    <date-picker
                      mode="date"
                      class="border-0 my-auto"
                      color="primary"
                      locale="en"
                      is-range
                      is-expanded

                      :value="filters.log_date"
                      :min-date="metadata.filters.log_date.minDate"
                      :max-date="metadata.filters.log_date.maxDate"
                      :input-props="{ class: 'border-0' }"
                      :first-day-of-week="2"

                      @input="onDatePickerInput"
                    />
                  </div>

                  <div class="col pt-5 pt-md-0">
                    <div
                      class="btn btn-outline-primary btn-block"
                      @click.prevent="onDatePickerInput({
                        start: $moment().startOf('day').toDate(),
                        end: $moment().endOf('day').toDate()
                      })"
                    >
                      Today
                    </div>

                    <div
                      class="btn btn-outline-primary btn-block"
                      @click.prevent="onDatePickerInput({
                        start: $moment().subtract(1, 'days').startOf('day').toDate(),
                        end: $moment().subtract(1, 'days').endOf('day').toDate()
                      })"
                    >
                      Yesterday
                    </div>

                    <div
                      class="btn btn-outline-primary btn-block"
                      @click.prevent="onDatePickerInput({
                        start: $moment().startOf('isoWeek').toDate(),
                        end: $moment().toDate()
                      })"
                    >
                      This week
                    </div>

                    <div
                      class="btn btn-outline-primary btn-block"
                      @click.prevent="onDatePickerInput({
                        start: $moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(),
                        end: $moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()
                      })"
                    >
                      Last week
                    </div>

                    <div
                      class="btn btn-outline-primary btn-block"
                      @click.prevent="onDatePickerInput({
                        start: $moment().startOf('month').toDate(),
                        end: $moment().toDate()
                      })"
                    >
                      This month
                    </div>

                    <div
                      class="btn btn-outline-primary btn-block"
                      @click.prevent="onDatePickerInput({
                        start: $moment().subtract(1, 'month').startOf('month').toDate(),
                        end: $moment().subtract(1, 'month').endOf('month').toDate()
                      })"
                    >
                      Last month
                    </div>
                  </div>
                </div>
              </template>
            </b-dropdown>
          </div>
          <!-- end::Date filter -->
        </div>

        <div class="separator separator-solid my-5" />

        <div class="d-flex justify-content-end">
          <button type="button" class="btn btn-white btn-active-light-primary mr-2" @click.prevent="resetFilters">Reset</button>
          <button type="button" class="btn btn-light-info" @click.prevent="applyFilters">Apply</button>
        </div>
      </form>
    </template>
    <!-- end::Dropdown items -->
  </b-dropdown>
</template>

<style lang="scss">
@import '@/assets/sass/plugins/vue-select/index.scss';
@import '@/assets/sass/plugins/datepicker/index.scss';
</style>

<script>
import vSelect from '@bartversluijs/vue-select';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import moment from 'moment-timezone';

import { SunbedErrors } from '@/libs/classes/equipment';

export default {
  name: 'overviewFilters',
  components: {
    vSelect,
    DatePicker,
  },
  data () {
    return {
      isDropdownHideable: true,

      filters: {
        type: null,
        'error.id': null,
        log_date: null,
      },

      metadata: {
        filters: {
          type: {
            filter (options, search) {
              return options.filter((option) => option.toLowerCase().includes(search.toLowerCase()), []);
            },

            options: [
              'Critical',
              'Non-critical',
            ],
          },

          'error.id': {
            filter (options, search) {
              return options.filter((option) => {
                const values = [option];
                if (this.$tu(`luxura_logs.error.errors.${option}.description`)) {
                  values.push(this.$tu(`luxura_logs.error.errors.${option}.description`));
                }

                return values.some((value) => value.toLowerCase().includes(search.toLowerCase()));
              }, []);
            },

            options: SunbedErrors.map((row) => row.error),
          },

          log_date: {

          },
        },
      },
    };
  },

  methods: {
    resetFilters () {
      this.$set(this.filters, 'type', null);
      this.$set(this.filters, 'error.id', null);
      this.$set(this.filters, 'log_date', null);

      this.applyFilters();
    },

    applyFilters () {
      this.$emit('apply', { ...this.filters });

      this.$set(this, 'isDropdownHideable', true);
      this.$refs.dropdown.hide();
    },

    async onSelectInput () {
      // Disable the ability to hide the dropdown
      // This is because of issues when selection an option which is outside of the dropdown
      this.$set(this, 'isDropdownHideable', false);
      setTimeout(() => this.$set(this, 'isDropdownHideable', true), 400);
    },

    onDropdownHide (e) {
      if (this.isDropdownHideable === false) {
        e.preventDefault();
      }
    },

    onDatePickerInput (range = null) {
      if (!range || !range.start || !range.end) {
        return;
      }

      if (range.start instanceof Date === false || range.end instanceof Date === false) {
        return;
      }

      // Hide dropdown and set range
      this.$refs['select:log_date:dropdown'].hide();
      this.$set(this.filters, 'log_date', {
        start: moment(range.start).startOf('day').toDate(),
        end: moment(range.end).endOf('day').toDate(),
      });

      this.onSelectInput();
    },
  },
};
</script>
